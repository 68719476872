var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Tooltip, Link, ListItemText } from '@mui/material';
// locales
import { useLocales } from '../../../locales';
// auth
import RoleBasedGuard from '../../../auth/RoleBasedGuard';
//
import Iconify from '../../iconify';
import { StyledItem, StyledIcon } from './styles';
// ----------------------------------------------------------------------
var NavItem = forwardRef(function (_a, ref) {
    var item = _a.item, depth = _a.depth, open = _a.open, active = _a.active, isExternalLink = _a.isExternalLink, other = __rest(_a, ["item", "depth", "open", "active", "isExternalLink"]);
    var translate = useLocales().translate;
    var title = item.title, path = item.path, icon = item.icon, children = item.children, disabled = item.disabled, caption = item.caption, roles = item.roles;
    var subItem = depth !== 1;
    var renderContent = (_jsxs(StyledItem, __assign({ ref: ref, open: open, depth: depth, active: active, disabled: disabled }, other, { children: [icon && _jsx(StyledIcon, { children: icon }), _jsx(ListItemText, { primary: "".concat(translate(title)), primaryTypographyProps: {
                    noWrap: true,
                    sx: __assign(__assign({ width: 72, fontSize: 10, lineHeight: '16px', textAlign: 'center' }, (active && {
                        fontWeight: 'fontWeightMedium',
                    })), (subItem && {
                        fontSize: 14,
                        width: 'auto',
                        textAlign: 'left',
                    })),
                } }), caption && (_jsx(Tooltip, __assign({ title: "".concat(translate(caption)), arrow: true, placement: "right" }, { children: _jsx(Iconify, { icon: "eva:info-outline", width: 16, sx: {
                        top: 11,
                        left: 6,
                        position: 'absolute',
                    } }) }))), !!children && (_jsx(Iconify, { width: 16, icon: "eva:chevron-right-fill", sx: {
                    top: 11,
                    right: 6,
                    position: 'absolute',
                } }))] })));
    var renderItem = function () {
        // ExternalLink
        if (isExternalLink)
            return (_jsx(Link, __assign({ href: path, target: "_blank", rel: "noopener", underline: "none" }, { children: renderContent })));
        // Default
        return (_jsx(Link, __assign({ component: RouterLink, to: path, underline: "none" }, { children: renderContent })));
    };
    return _jsxs(RoleBasedGuard, __assign({ roles: roles }, { children: [" ", renderItem(), " "] }));
});
export default NavItem;
